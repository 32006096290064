import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class XsrfInterceptor implements HttpInterceptor {
  constructor(private router: Router, private tokenExtractor: HttpXsrfTokenExtractor) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let newReq;
    const cookieheaderName = 'X-XSRF-TOKEN';
    const csrfToken = this.tokenExtractor.getToken();
    console.log('Inside the xsrfinterceptor');
    if (csrfToken !== null && !req.headers.has(cookieheaderName)) {
      newReq = req.clone({ headers: req.headers.set(cookieheaderName, csrfToken) });
    } else {
      newReq = req;
    }

    return next.handle(newReq);
  }
}
